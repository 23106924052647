html {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    min-height: 100%;
    background-color: black;
}

body {
    margin: 0;
    min-height: 100%;
}

svg {
    filter: invert(100%);

}

.container {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: auto;
    grid-template-areas: 
        "header header header"
        "left-content landing right-content"
        "left-content content right-content"
        "footer footer footer";
    position: relative;

}

/* Landing stuff */
.landing {
    position: relative;
    grid-area: header;
    display: block;
    text-align: center;
    background-color: rgba(0, 0, 0, 1);
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    justify-self: center;


}

.landing-text {
    font-size: 3rem;
    padding-top: 10vw;
    padding-bottom: 2rem;
    color: rgba(255, 255, 255, 1);
    justify-self: center;
    align-self: center;

}

.landing-subtext {
    font-size: 2rem;
    color: rgba(255, 255, 255, .8);
    padding-bottom: 1vw;

}

.icons > a > svg {
    height: auto;
    width: 4vw;
    transition: opacity .5s;
    padding-bottom: 4vw;
    opacity: 0.9;
    padding-right: 1vw;

}

.icons > a > svg:hover {
    opacity: 0.5;

}


/* projects area */
.project-banner > span {
    font-size: 7vw;
    color: rgba(250, 250, 250, 0.95);
    position: absolute;

}

.project-banner {
    display: block;

    /*wa fade*/
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    url('../../public/assets/wa-preview.png');
    background-attachment: local;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 100%;
    height: 32vw;
}

.projects-container {
    grid-area: content;
    position: relative;

}

.project {
    display: block;

    width: 80%;
    border: 2px solid rgba(255, 255, 255, .8);
    padding: 1rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;

}

.project > a:link, a:visited, a:active {
    font-size: 2vw;
    text-decoration: none;
    color: white;
    padding-bottom: 1rem;
    transition: color 500ms;

}

.project > a:hover {
    color: rgba(255, 255, 255, .6);

}

.project > p > a:link, a:visited, a:active {
    text-decoration: none;
    color: white;
    padding-bottom: 1rem;
    transition: color 500ms;
    text-decoration: underline white 0.05rem;
}

.project > p > a:hover {
    color: rgba(255, 255, 255, .6);
}

.project > p {
    color: rgba(255, 255, 255, .85);
    font-size: 1vw;
}

/* Catagories */
.rust-cat {
    color: #dea584;
    padding-left: 0.5vw;
    font-size: 1.3vw;
}

.ts-cat {
    color: #3178C6;
    padding-left: 0.5vw;
    font-size: 1.3vw;
}

.cpp-cat {
    color: #f34b7d;
    padding-left: 0.5vw;
    font-size: 1.3vw;
}

.py-cat {
    color: #3572A5;
    padding-left: 0.5vw;
    font-size: 1.3vw;
}

.ros2-cat {
    color: #31466e;
    padding-left: 0.5vw;
    font-size: 1.3vw;
}

.cs-cat {
    color: #178600;
    padding-left: 0.5vw;
    font-size: 1.3vw;
}


.line {
    display: inline-block;
    width: 90%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 1rem;
}

/* other formating */
.left-wall {
    grid-area: left-content;

}

.right-wall {
    grid-area: right-content;

}


/* smaller desktop screens */
@media only screen and (max-width: 1500px) {
    .container {
        grid-template-columns: 0% 100% 0%;
    }

    .project-banner {

        height: 60vw;
    }

    .project-banner > span {
        font-size: 12vw;
    }

    .project > a:link, a:visited, a:active {
        font-size: 3vw;

    }

    .project > p {
        font-size: 1.6vw;
    }

    .rust-cat {
        font-size: 2.3vw;
        padding-left: 0.8vw;
    }
    
    .ts-cat {
        font-size: 2.3vw;
        padding-left: 0.8vw;
    }
    
    .cpp-cat {
        font-size: 2.3vw;
        padding-left: 0.8vw;
    }
    
    .py-cat {
        font-size: 2.3vw;
        padding-left: 0.8vw;
    }
    
    .ros2-cat {
        font-size: 2.3vw;
        padding-left: 0.8vw;
    }
    
    .cs-cat {
        font-size: 2.3vw;
        padding-left: 0.8vw;
    }

    .icons > a > svg {
        width: 6vw;

    }
}


/* smaller screens */
@media only screen and (max-width: 950px) {
    .container {
        grid-template-columns: 0% 100% 0%;
    }

    .project-banner > span {
        font-size: 16vw;
    }

    .project > a:link, a:visited, a:active {
        font-size: 6vw;

    }

    .project > p {
        font-size: 4vw;
    }

    .rust-cat {
        font-size: 3.5vw;
        padding-left: 0.4vw;
    }
    
    .ts-cat {
        font-size: 3.5vw;
        padding-left: 0.4vw;
    } 
    
    .py-cat {
        font-size: 3.5vw;
        padding-left: 0.4vw;
    }
    
    .ros2-cat {
        font-size: 3.5vw;
        padding-left: 0.4vw;
    }
    
    .cs-cat {
        font-size: 3.5vw;
        padding-left: 0.4vw;
    }

    .cpp-cat {
            font-size: 3.5vw;
            padding-left: 0.4vw;
        }

    .icons > a > svg {
        width: 12vw;

    }

}

